* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  
}

.justifyptext{
  text-align: justify;
  text-justify: inter-word;

}