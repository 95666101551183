.header-banner .banner-content .sub-title {
  font-size: 32px;
  color: darkred;
  text-transform: uppercase;
  text-align: center;
  padding-top: 1.5em;
}

.header-banner .banner-content .sub-title2 {
  font-size: 26px;
  color: white;
  text-align: center;
  padding-top: 10%;
}

@media (max-width: 767px) {
  .header-banner .banner-content .sub-title {
    font-size: 18px;
    text-align: center;
  }
  .header-banner .banner-content .sub-title2 {
    font-size: 18px;
    text-align: center;
  }
}

.header-banner .banner-content .banner-title {
  font-size: 70px;
  text-align: center;
}

.header-banner .banner-content .banner-title span {
  color: darkred;
  text-align: center;

}

@media (max-width: 767px) {
  .header-banner .banner-content .banner-title {
    font-size: 38px;
    text-align: center;

  }
}

.header-banner .banner-content .banner-contact {
  position: relative;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Barlow', sans-serif;
  color: darkred;
  text-transform: uppercase;
  margin-left: 70px;
  text-align: center;

}

.header-banner .banner-content .banner-contact::before {
  position: absolute;
  content: '';
  width: 60px;
  height: 2px;
  background-color: darkred;
  left: -70px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;

}

/* Wrapper */

.wrapper {
  padding: 3em 0 0 0;
  position: relative;
}

.wrapper .inner {
  margin: 0 auto;
  width: 80em;
  position: relative;
  z-index: 99;
}

.wrapper header {
  margin-bottom: 4em;
}

@media screen and (max-width: 736px) {

  .wrapper header {
    margin-bottom: 2em;
  }

}

.wrapper.style1 {
  background-color: #fafafa;
  color: #999999;
}


.wrapper.style2 {
  background-color: #000000;
  color: #000000;
}


.wrapper.style2 .image {
  box-shadow: 5px 4px 20px black;
}

@media screen and (max-width: 1280px) {

  .wrapper>.inner {
    width: 60em;
  }

}

@media screen and (max-width: 980px) {

  .wrapper>.inner {
    width: 90%;
  }

}

@media screen and (max-width: 736px) {

  .wrapper {
    padding: 1em 0 2em 0;
  }

}

/* Flex */

.flex {
  display: -ms-flexbox;
  -ms-flex-pack: justify;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}

.flex.flex-2,
.flex.flex-3 {
  -moz-align-items: stretch;
  -webkit-align-items: stretch;
  -ms-align-items: stretch;
  align-items: stretch;
}

.flex.flex-2.center,
.flex.flex-3.center {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

.flex.flex-2 .col {
  width: 48%;
}

.flex.flex-2 .col.col1 {
  width: 50%;
}

.flex.flex-2 .col.col2 {
  width: 48%;
  padding-right: 10px;
}

.flex.flex-2 .col.col3 {
  width: 30%;
}

.flex.flex-2 .col.col4 {
  width: 64%;
  margin-top: 2em;
}


@media screen and (max-width: 736px) {

  .flex.flex-2 .col.col1,
  .flex.flex-2 .col.col2 {
    width: 100%;
    margin-bottom: 2em;
  }

  .flex.flex-2 .col.col3,
  .flex.flex-2 .col.col4 {
    width: 100%;
    margin-bottom: 2em;
  }
  .flex.flex-2 .col.col1 .image,
  .flex.flex-2 .col.col2 .image {
    max-width: 80%;
    margin: 0 auto 0em auto;
  }

}

.flex.flex-3 .col {
  width: 28%;
}

@media screen and (max-width: 736px) {

  .flex.flex-3 .col {
    width: 100%;
    margin-bottom: 2em;
  }

  .flex.flex-3 .col .image {
    max-width: 60%;
    margin: 0 auto 2em auto;
  }

}

.flex.flex-4 .col {
  width: 22%;
}

.flex.flex-tabs {
  -moz-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.flex.flex-tabs ul {
  width: 20%;
}

.flex.flex-tabs .tabs {
  width: 77%;
}

@media screen and (max-width: 980px) {

  .flex.flex-4 .col {
    width: 48%;
  }

  .flex.flex-tabs ul {
    width: 30%;
  }

  .flex.flex-tabs .tabs {
    width: 67%;
  }

  .flex.flex-tabs .flex-3 .col {
    width: 48%;
  }

}

@media screen and (max-width: 736px) {

  .flex.flex-2 .col,
  .flex.flex-3 .col,
  .flex.flex-4 .col {
    width: 100%;
    -moz-order: 2;
    -webkit-order: 2;
    -ms-order: 2;
    order: 2;
  }

  .flex.flex-2 .col.first,
  .flex.flex-3 .col.first,
  .flex.flex-4 .col.first {
    -moz-order: 1;
    -webkit-order: 1;
    -ms-order: 1;
    order: 1;
    padding-top: 10px;

  }

  .flex.flex-tabs {
    -moz-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .flex.flex-tabs ul {
    width: 100%;
  }

  .flex.flex-tabs .tabs {
    width: 100%;
  }

  .flex.flex-tabs .flex-3 .col {
    width: 100%;
  }

}

/* Image */

.image {
  border: 0;
  display: inline-block;
  position: relative;
  box-shadow: 5px 5px 18px black;
}

.image img {
  display: block;
}

.image.left,
.image.right {
  max-width: 40%;
}

.image.left img,
.image.right img {
  width: 100%;
}

.image.left {
  float: left;
  margin: 0 1.5em 1em 0;
  top: 0.25em;
}

.image.right {
  float: right;
  margin: 0 0 1em 1.5em;
  top: 0.25em;
}

.image.fit {
  display: float;
  margin: 0 0 -5% 0;
  width: 100%;
}

.image.fit img {
  width: 100%;
  transition: all 0.2s linear;

}
.image.fit img:hover {
  transform: scale(1.05);
}

.image.fit2 {
  display: float;
  margin: 0 0 -2% 0;
  width: 100%;
}

.image.fit2 img {
  width: 100%;
  transition: all 0.2s linear;
}
.image.fit2 img:hover {
  transform: scale(1.05);
}

.image.round {
  border-radius: 100%;
}

.image.round img {
  border-radius: 100%;
  transition: all 0.2s linear;
}
.image.round img:hover {
  transform: scale(1.05);
}

.image.square {
  border-radius: 3%;
}

.image.square img {
  border-radius: 3%;
}

.image.square2 img {
  border-radius: 3%;
}

.image.main {
  display: block;
  margin: 0 0 3em 0;
  width: 100%;
}

.image.main img {
  width: 100%;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */

  padding-top: 25px;
  height: 0;
}

.video-wrapper iframe,
.video-wrapper object,
.video-wrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.joinUsMiddleText  {
  font-size: 13pt;
  font-weight: 300;
  line-height: 1.65;
  font-family: Arial, Helvetica, sans-serif

}

.li {
  font-family: "Scope One", serif;
  line-height: 1.65;
}

.joinlinks {
  text-decoration: none;
  color: inherit; }

