.image.fit2 {
  display: float;
  margin: 0 0 2% 0;
  width: 100%;
}


hr {
  border: 0;
  border-bottom: solid 1px;
  padding: 1em 0;
}

hr.major {
  padding: 1em 0;
}

.image.round3 {
  border-radius: 100%;
  transform: scale(0.8);
  margin-bottom: 20px;
}

.image.round3 img {
  border-radius: 100%;
  transition: all 0.2s linear;
}

.image.round3 img:hover {
  transform: scale(1.05);
}

.flex.flex-2 .col.cola1 {
  width: 25%;
}

.flex.flex-2 .col.cola2 {
  width: 75%;
}

.flex.flex-3 {
  margin-bottom: 50px;
}

.addi {
  margin-top: 20px;
  color: rgb(65, 65, 65);

}


@media screen and (max-width: 736px) {

  .flex.flex-2 .col.cola1,
  .flex.flex-2 .col.cola2 {
    width: 100%;
    margin-bottom: 2em;
  }

  .flex.flex-2 .col.cola1 .image,
  .flex.flex-2 .col.cola2 .image {
    max-width: 80%;
    margin: 0 auto 0em auto;
  }

  .flex.flex-2 .col.first,
  .flex.flex-3 .col.first,
  .flex.flex-4 .col.first {
    -moz-order: 1;
    -webkit-order: 1;
    -ms-order: 1;
    order: 1;
    padding-top: 10px;
  }

  .addi {
    margin-top: 0px;
  }
  .topreg {
    padding-top:40px;

  }
}

.msabouttitle {
  font-size: 48px;
  color: rgb(65, 65, 65);
}

.msmaintext {
  font-size: 20px;
  color: rgb(65, 65, 65);
  
}

h2{
  color: rgb(65, 65, 65);
}

.topreg {
  margin-top:-50px;
}
