#cf img {
    position: absolute;
    left: 0;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    display: block;
    width: 100%;
}

#cf {
    /* background: url('/images/C1_exp_darkmares_2019-11-16_17-46-37_v1_EXP_2048.jpg') center center/cover no-repeat; */
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

@keyframes cf3FadeInOut {
    0% {
        opacity: 1;
    }

    45% {
        opacity: 1;
    }

    55% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

#cf img.top {
    animation-name: cf3FadeInOut;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 6s;
    animation-direction: alternate;
}

.hero-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.hero-container>h1 {
    color: #fff;
    font-size: 80px;
    margin-top: -100px;
}

.hero-container>p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}

.graybackg {
    width: 100%;
    background: rgba(25, 25, 25, .6);
    text-align: center;
    padding: 10px;
}

.droptextshad {
    text-shadow: 2px 2px 5px black;
}

@media screen and (max-width: 960px) {
    .hero-container>h1 {
        font-size: 70px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container>h1 {
        font-size: 50px;
        margin-top: -100px;
    }

    .hero-container>p {
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }

    #cf {
        height: 55vh;
    }

    .hero-container {
        height: 45vh;
        padding-top: 100px;
    }
}
