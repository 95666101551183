.styled-table {
  border-collapse: collapse;
  margin: 25px ;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.2em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;}


.bb {
  background-color: darkred;
  color: #ffffff;
  text-align: center;

}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid darkred;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: darkred;
}

table { 
  min-width: 90%; 
  align-items: center;
  border-collapse: collapse; 
}
/* Zebra striping */
tr:nth-of-type(odd) { 
  background: #eee; 
}
th { 
  background: #333; 
  color: white; 
  font-weight: bold; 
}
td, th { 
  padding: 6px; 
  border: 1px solid #ccc; 
  text-align: center; 
  border-radius: 5px;
}

@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 1px solid #ccc; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}

}


.image.round2 {
  border-radius: 100%;
  transform: scale(0.8);

}

.image.round2 img {
  border-radius: 100%;
  transition: all 0.2s linear;
}
.image.round2 img:hover {
  transform: scale(1.05);
}

.wrapper.stylesch {
  background-color: #fafafa;
  color: #999999;
  margin-top:-2em;
}
